import type { Property } from 'api/apiTypes';
import { MultipropertySwitcher } from './MultiPropertySwitcher';

type DetailPageLayoutProps = {
  children: JSX.Element;
  headerContent: JSX.Element;
  rightContent: JSX.Element;
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
};

export function DetailPageLayout({
  children,
  headerContent,
  rightContent,
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
}: DetailPageLayoutProps): JSX.Element {
  return (
    <>
      <div className="flex flex-col md:pb-8 md:grid md:grid-cols-main">
        <div className="col-span-1">
          <div className="text-center">{headerContent}</div>
        </div>
      </div>
      <MultipropertySwitcher
        {...{ selectedPropertyId, setSelectedPropertyId, properties }}
      />

      <div
        className="flex flex-col md:grid lg:grid-cols-main mdd:mt-4 gap-4"
        style={{ maxWidth: '962px' }}
      >
        <div className="col-span-1">{children}</div>
        <div className="col-span-1">{rightContent}</div>
      </div>
    </>
  );
}
