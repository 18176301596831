import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Dashboard } from 'components/Dashboard/Dashboard';
import { Activities } from 'components/Activities/Activities';
import { Dates } from 'components/Dates/Dates';
import { Offers } from 'components/Offers/Offers';
import { Contract } from 'components/Contract/Contract';
import { useEffect, useMemo, useState } from 'react';
import { Layout } from './Layout';
import { DevTool } from './DevTool/DevTool';
import { usePropertyQueryForPerson } from './DevTool/usePropertyQueryForPerson';
import dayjs from 'dayjs';
import Login from './Login';
import Welcome from './Welcome';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';

export const LOAD_EXAMPLE_PATH = 'loadexample';

const userAuthPaths = [
  'login',
  LOAD_EXAMPLE_PATH,
  'reset-password',
  'new-password',
  'welcome',
];

function PropertyProvider(): JSX.Element | null {
  const [
    selectedPersonId,
    setSelectedPersonId,
  ] = useState('');
  const [
    selectedPropertyId,
    setSelectedPropertyId,
  ] = useState(0);
  const propertyQuery = usePropertyQueryForPerson(
    selectedPersonId || '3wJjphRvgeJDuFe9L',
  );
  const hasError =
    propertyQuery.error !== null || propertyQuery?.data?.statusCode === 401;
  const isUserAuthPage = userAuthPaths.includes(
    window.location.pathname.split('/')[1],
  );

  useEffect(() => {
    if (propertyQuery.data?.properties?.assetAccessToken) {
      document.cookie = `assetAccessToken=${
        propertyQuery.data?.properties?.assetAccessToken
      };Domain=${window.location.host
        .replace(/www\./, '')
        .replace(/app\./, '')
        .replace(/appdev\./, '')};SameSite=Strict;secure;path=/`;
    }
  }, [propertyQuery.data]);

  const defaultProps = useMemo(() => {
    let selectedProperty =
      propertyQuery.data?.properties?.properties[selectedPropertyId];
    if (selectedProperty) {
      selectedProperty = {
        ...selectedProperty,
        activities:
          selectedProperty.activities.map((act) => ({
            ...act,
            createdAt: new Date(act.createdAt),
          })) || [],
        calendarEvents:
          selectedProperty.calendarEvents.map((event) => ({
            ...event,
            date: new Date(event.date),
          })) || [],
        offers: selectedProperty.offers.map((offer) => {
          return {
            ...offer,
            date: new Date(offer.date),
          };
        }),
        microActivities: selectedProperty.microActivities.map(
          (microAcitivity) => {
            return {
              ...microAcitivity,
              createdAt: new Date(microAcitivity.createdAt),
            };
          },
        ),
      };
    }
    return {
      properties: propertyQuery.data?.properties?.properties || [],
      selectedProperty,
    };
  }, [
    selectedPropertyId,
    propertyQuery.data,
  ]);

  const FixedRouter: any = Router;
  const FixedSwitch: any = Switch;
  const FixedRoute: any = Route;

  return (
    <FixedRouter>
      <Layout
        multipleProperties={defaultProps?.properties.length > 1}
        property={defaultProps.selectedProperty}
        selectedPropertyNr={selectedPropertyId}
        devTool={
          <DevTool
            selectedPersonId={selectedPersonId}
            setSelectedPersonId={setSelectedPersonId}
          />
        }
      >
        <div className="pt-11 pr-3 lg:pr-2">
          <main style={{ maxWidth: '962px' }}>
            <FixedSwitch>
              <FixedRoute path="/as-healthcheck">
                <p>Works</p>
              </FixedRoute>
              {defaultProps.selectedProperty ? (
                <>
                  <FixedRoute path="/dashboard">
                    <Dashboard
                      {...defaultProps}
                      selectedPropertyId={selectedPropertyId}
                      setSelectedPropertyId={setSelectedPropertyId}
                    />
                  </FixedRoute>
                  <FixedRoute path="/activities">
                    <Activities
                      selectedPropertyId={selectedPropertyId}
                      setSelectedPropertyId={setSelectedPropertyId}
                      {...defaultProps}
                    />
                  </FixedRoute>
                  <FixedRoute path="/dates">
                    <Dates
                      selectedPropertyId={selectedPropertyId}
                      setSelectedPropertyId={setSelectedPropertyId}
                      selectedProperty={defaultProps.selectedProperty}
                      properties={defaultProps.properties}
                    />
                  </FixedRoute>
                  <FixedRoute path="/offers">
                    <Offers
                      selectedPropertyId={selectedPropertyId}
                      setSelectedPropertyId={setSelectedPropertyId}
                      selectedProperty={defaultProps.selectedProperty}
                      properties={defaultProps.properties}
                    />
                  </FixedRoute>
                  <FixedRoute path="/contract">
                    <Contract
                      selectedPropertyId={selectedPropertyId}
                      setSelectedPropertyId={setSelectedPropertyId}
                      selectedProperty={defaultProps.selectedProperty}
                      properties={defaultProps.properties}
                    />
                  </FixedRoute>
                  <FixedRoute path="/">
                    <Redirect to="/dashboard" />
                    {/* <Dashboard
                      selectedPropertyId={selectedPropertyId}
                      setSelectedPropertyId={setSelectedPropertyId}
                      selectedProperty={defaultProps.selectedProperty}
                      properties={defaultProps.properties}
                    ></Dashboard> */}
                  </FixedRoute>
                  {propertyQuery.isFetching ? <div>...loading</div> : null}
                </>
              ) : (
                <>
                  {hasError && !isUserAuthPage ? (
                    <Redirect to="/login" />
                  ) : null}
                  <FixedRoute path="/welcome/:token">
                    <Welcome />
                  </FixedRoute>
                  <FixedRoute path="/login">
                    <Login />
                  </FixedRoute>
                  <FixedRoute path={`/${LOAD_EXAMPLE_PATH}`}>
                    <ExampleAction />
                  </FixedRoute>
                  <FixedRoute path="/reset-password">
                    <ResetPassword />
                  </FixedRoute>
                  <FixedRoute path="/new-password/:token">
                    <NewPassword />
                  </FixedRoute>
                </>
              )}
            </FixedSwitch>
          </main>
        </div>
      </Layout>
    </FixedRouter>
  );
}

function ExampleAction() {
  useEffect(() => {
    document.cookie = `authToken=example;Expires=${getCookieExpirationDate()};SameSite=Strict;secure;path=/`;
    document.cookie = `userId=example;Expires=${getCookieExpirationDate()};SameSite=Strict;secure;path=/`;

    window.location.replace(`/dashboard/`);
  }, []);

  return <p>Loading...</p>;
}

export function getCookieExpirationDate() {
  return dayjs(new Date()).add(30, 'days').toDate().toUTCString();
}

export default PropertyProvider;
