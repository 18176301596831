import type { CalendarEvent, Property } from 'api/apiTypes';
import { Clippy } from 'components/Dashboard/Clippy';
import { Notes } from 'components/Dashboard/Notes';
import { DetailPageLayout } from 'components/DetailPageLayout';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';

type DatesDayBlockProps = {
  date: Date;
  dates: CalendarEvent[];
  small?: boolean;
};

function DatesDayBlock({ date, dates }: DatesDayBlockProps): JSX.Element {
  const { i18n, t } = useTranslation();
  return (
    <>
      {/* {dates.map((eventDate) => {
        const eventDateAsDate = new Date(eventDate.date)
        return ( */}
      <div className="border-2 py-5 px-6 relative">
        <h5 className="text-lg font-bold">
          {formatDate(date)} -{' '}
          {date.toLocaleDateString(i18n.language, { weekday: 'long' })}
        </h5>
        <div className="grid grid-cols-12 mt-2 text-gray-300 text-xs font-bold border-b-2">
          <div className="col-span-2">
            {t('dates_table_dates_block_time_text', 'Zeit')}
          </div>
          <div className="pl-2 border-l-2 col-span-4">
            {t('dates_table_dates_block_availability_text', 'Verfügbar')}
          </div>
          <div className="pl-2 border-l-2 col-span-6">
            {t('dates_table_dates_block_interested_text', 'Interessent')}
          </div>
        </div>
        {dates
          .sort(
            (eventA, eventB) => eventA.date.getTime() - eventB.date.getTime(),
          )
          .map((calenderEvent) => {
            return (
              <div
                key={calenderEvent._id}
                className="grid grid-cols-12 mt-4"
              >
                <div className="col-span-2">
                  {calenderEvent.date.getHours()}:
                  {('0' + calenderEvent.date.getMinutes()).slice(-2)}
                </div>
                <div className="pl-2 col-span-4">
                  {calenderEvent.booked
                    ? t(
                        'dates_table_dates_block_availability_booked_text',
                        'gebucht',
                      )
                    : calenderEvent.date < new Date()
                    ? t(
                        'dates_table_dates_block_availability_not_booked_text',
                        'Nicht gebucht',
                      )
                    : t(
                        'dates_table_dates_block_availability_open_text',
                        'offen',
                      )}
                </div>
                <div className="pl-2 col-span-6">
                  {calenderEvent.bookedPerson
                    ? `${calenderEvent.bookedPerson.firstname || ''} ${
                        calenderEvent.bookedPerson.lastname || ''
                      }`
                    : ''}
                </div>
              </div>
            );
          })}
      </div>
      {/* )
      })} */}
    </>
  );
}

type DatesProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
  selectedProperty: Property;
};

export function Dates({
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
  selectedProperty,
}: DatesProps): JSX.Element {
  const upcomingDates = useMemo(() => {
    return [
      ...new Set(
        selectedProperty.calendarEvents.map((event) =>
          dayjs(event.date).startOf('day').toDate().toISOString(),
        ),
      ),
    ].filter((day) => dayjs(day).isAfter(new Date()));
  }, [selectedProperty.calendarEvents]);

  const { t } = useTranslation();

  const pastDates = useMemo(() => {
    return [
      ...new Set(
        selectedProperty.calendarEvents.map((event) =>
          dayjs(event.date).startOf('day').toDate().toISOString(),
        ),
      ),
    ].filter((day) => dayjs(day).isBefore(new Date()));
  }, [selectedProperty.calendarEvents]);

  if (!selectedProperty) {
    return <div>loading...</div>;
  }

  const rightContent = (
    <div>
      <Notes property={selectedProperty}></Notes>
      <Clippy></Clippy>
    </div>
  );

  return (
    <DetailPageLayout
      {...{ selectedPropertyId, setSelectedPropertyId, properties }}
      headerContent={<h4>{t('dates_title')}</h4>}
      rightContent={rightContent}
    >
      <div className="md:mr-4">
        <div className="grid gap-4 pb-8">
          {upcomingDates.map((day) => {
            return (
              <DatesDayBlock
                key={day}
                date={new Date(day)}
                dates={selectedProperty.calendarEvents.filter(({ date }) => {
                  return dayjs(day).isSame(date, 'day');
                })}
              />
            );
          })}
        </div>
        {pastDates.length ? (
          <>
            {' '}
            <div className="text-center pb-4">
              <h5 className="text-lg font-bold">{t('dates_subtitle')}</h5>
            </div>
            <div className="grid gap-4">
              {pastDates.map((day) => {
                return (
                  <DatesDayBlock
                    key={day}
                    date={new Date(day)}
                    dates={selectedProperty.calendarEvents
                      .filter(({ date }) => {
                        return dayjs(day).isSame(date, 'day');
                      })
                      .map((event) => ({
                        ...event,
                        type: t('dates_dates_nicht_gebucht', 'Nicht gebucht'),
                      }))}
                  />
                );
              })}
            </div>
            <br />
          </>
        ) : null}
      </div>
    </DetailPageLayout>
  );
}
