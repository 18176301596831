import type { PropertyQueryResult } from '../components/DevTool/DevTool';
import { getValueFromCookie } from '../utils/getValueFromCookie';
import { exampleDataProperties } from '../data/exampleDataProperties';

export const fetchProperties = async (
  params: any,
): Promise<PropertyQueryResult> => {
  const authToken = getValueFromCookie('authToken') || '';
  const userId = getValueFromCookie('userId') || '';

  if (authToken === 'example' || userId === 'example') {
    return {
      success: true,
      result: 'success',
      properties: {
        properties: exampleDataProperties as any,
      },
    };
  }

  const [
    ,
    { personId },
  ] = params.queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_HOST}/propertiesForCockpitUser`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
        'X-User-Id': userId,
      },
      body: JSON.stringify({ personId }),
    },
  );

  if (!response.ok) {
    throw new Error('Problem fetching data');
  }

  return response.json();
};
