import { useQuery } from 'react-query';
import { fetchProperties } from '../../api/fetchProperties';
import type { PropertyQueryResult } from './DevTool';

export const usePropertyQueryForPerson = (personId: string) => {
  return useQuery<PropertyQueryResult, Error>(
    [
      'properties',
      { personId },
    ],
    fetchProperties,
    {
      staleTime: 60 * 60 * 5,
      refetchOnWindowFocus: false,
      enabled: Boolean(personId),
      retry: false,
    },
  );
};
