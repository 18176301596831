import type { Property } from 'api/apiTypes';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { assetUrl } from '../../utils/assetUrl';

type DashboardContractProps = {
  property: Property | undefined;
  selectedPropertyNumber?: number;
};

export function DashboardContract({
  property,
  selectedPropertyNumber,
}: DashboardContractProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="p-4 md:mr-4 border-2">
      <div className="text-lg font-bold mb-4">
        {t('dashboard_dashboard_contract_vertrag', 'Vertrag')}{' '}
        {selectedPropertyNumber !== undefined ? selectedPropertyNumber : ''}
      </div>
      <div className="font-bold">
        {t('dashboard_dashboard_contract_adresse', 'Adresse')}
      </div>
      {property?.street} {property?.no}
      <br />
      {property?.zip} {property?.city}
      <br />
      <div className="font-bold mt-4">
        {t('dashboard_dashboard_contract_dauer', 'Dauer')}
      </div>
      {formatDate(property?.sellContractStartDate)} -{' '}
      {formatDate(property?.sellContractEndDate)}
      <br />
      <div className="font-bold mt-4">
        {t('dashboard_dashboard_contract_dokumente', 'Dokumente')}
      </div>
      <div className="flex gap-4">
        <a
          href={assetUrl(property?.contractDownloadUrl, {
            secure: true,
          })}
          className="as-link"
          download
          target="_blank"
          rel="noreferrer"
        >
          {t('dashboard_dashboard_contract_vertrag', 'Vertrag')}
        </a>
      </div>
    </div>
  );
}
