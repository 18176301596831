import type { Property } from 'api/apiTypes';
import { Person } from 'components/Person';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../../utils/assetUrl';

type ContactPersonsProps = {
  property: Property | undefined;
};

export function ContactPersons({ property }: ContactPersonsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      className="p-4 border-2"
      style={{ minHeight: '15em' }}
    >
      <div className="text-lg font-bold">
        {t(
          'dashboard_contact_persons_ihre_immobilienberater',
          'Ihre Immobilienberater',
        )}
      </div>
      <div className="mb-6">
        <Person
          name={
            property?.internalAgent.firstname +
            ' ' +
            property?.internalAgent.lastname
          }
          title="Immobilienberater"
          email={property?.internalAgent.email}
          phone={property?.internalAgent.phone}
          photoUrl={assetUrl(property?.internalAgent.photoUrl)}
        />
      </div>
      <Person
        name={
          property?.externalAgent.firstname +
          ' ' +
          property?.externalAgent.lastname
        }
        title="Immobilienberater"
        email={property?.externalAgent.email}
        phone={property?.externalAgent.phone}
        photoUrl={assetUrl(property?.externalAgent.photoUrl)}
      />
    </div>
  );
}
