export default function formatMoney(num: number) {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
    maximumFractionDigits: 0,
  });

  return formatter.format(num);
}
