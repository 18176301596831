import { assetUrl } from '../utils/assetUrl';

type PersonProps = {
  name: string;
  title: string;
  email?: string;
  phone?: string;
  photoUrl?: string;
};

export function Person({
  name,
  title,
  email,
  phone,
  photoUrl,
}: PersonProps): JSX.Element {
  return (
    <div>
      <div className=" p-4 pl-0 mb-2 flex flex-row">
        <img
          className="h-16 mr-4"
          src={assetUrl(photoUrl, {
            postfix: '/140x140',
          })}
          alt="Person"
        />
        <div>
          {name}
          <br />
          {title}
        </div>
      </div>
      <div>
        {email}
        <br />
        {phone}
        <br />
      </div>
    </div>
  );
}
