import { Link, useLocation } from 'react-router-dom';
import { AppSidebarProperty } from './AppSidebarProperty';
import { GrLineChart } from 'react-icons/gr';
import { FiCalendar } from 'react-icons/fi';
import { FaRegMoneyBillAlt, FaUserCircle } from 'react-icons/fa';
import { GoChecklist } from 'react-icons/go';
import { BsBarChart } from 'react-icons/bs';

import './AppSidebar.css';
import type { Property } from 'api/apiTypes';
import { useTranslation } from 'react-i18next';

type NavElementProps = {
  to: string;
  title: string;
  icon: JSX.Element;
  className: string;
};

function NavElement({
  to,
  title,
  icon,
  className,
}: NavElementProps): JSX.Element {
  const location = useLocation();
  const pathName = location.pathname === '/' ? '/dashboard' : location.pathname;
  const elClassName =
    className +
    ' AppSidebarNavElement ' +
    (pathName.indexOf(to) === 0 ? 'as-color-blue font-bold' : '');

  return (
    <li className={elClassName}>
      <div className="flex flex-row gap-4">
        <div style={{ width: '2.5em' }}>
          <div className="float-right">{icon}</div>
        </div>
        <div>
          <Link to={to}>{title}</Link>{' '}
        </div>
      </div>
    </li>
  );
}

type AppSidebarProps = {
  property: Property;
  multipleProperties: boolean;
  selectedPropertyNr: number;
  burgerMenuOpen: boolean;
  setBurgerMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const sideBarClass = 'hidden md:block md:col-span-3 BurgerMenuOpen';

export function AppSidebar({
  burgerMenuOpen,
  setBurgerMenuOpen,
  property,
  multipleProperties,
  selectedPropertyNr,
}: AppSidebarProps): JSX.Element {
  const { t } = useTranslation();
  const navElements = [
    {
      to: '/dashboard',
      title: t('menu_nav_element_dashboard_title', 'Dashboard'),
      icon: <BsBarChart />,
      className: '',
    },
    {
      to: '/activities',
      title: t('menu_nav_element_activities_title', 'Aktivitäten'),
      icon: <GrLineChart />,
      className: '',
    },
    {
      to: '/dates',
      title: t('menu_nav_element_dates_title', 'Termine'),
      icon: <FiCalendar />,
      className: '',
    },
    {
      to: '/offers',
      title: t('menu_nav_element_offers_title', 'Angebote'),
      icon: <FaRegMoneyBillAlt />,
      className: '',
    },
    {
      to: '/contract',
      title: t('menu_nav_element_contract_title', 'Vertrag'),
      icon: <GoChecklist />,
      className: '',
    },
    {
      to: '/logout',
      title: t('menu_nav_element_logout_title', 'Logout'),
      icon: <FaUserCircle />,
      className: 'block md:hidden',
    },
  ].map((data: NavElementProps) => {
    return (
      <NavElement
        {...data}
        key={data.to}
      />
    );
  });

  const languageSwitch = (
    <div
      className="grid grid-cols-3"
      style={{ textAlign: 'center' }}
    >
      <div>DE</div>
      <div>EN</div>
      <div>FR</div>
    </div>
  );

  return (
    <aside
      data-burger={burgerMenuOpen ? 'open' : ''}
      className={`border-gray-200 md:border-r-2 ${sideBarClass} md:pr-3 lg:pr-6 md:ml-3 lg:ml-auto`}
      onClick={() => setBurgerMenuOpen(false)}
    >
      <AppSidebarProperty
        property={property}
        multipleProperties={multipleProperties}
        selectedPropertyNr={selectedPropertyNr}
      />
      <hr />
      <nav className="pt-16">
        <ul>{navElements}</ul>
      </nav>
      <div className="block md:hidden">
        <hr />
        <div>{languageSwitch}</div>
      </div>
    </aside>
  );
}
