import { withTolgee } from '@tolgee/i18next';
import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

const config: any = {
  apiUrl: sessionStorage.__tolgee_apiUrl || 'https://app.tolgee.io',
  filesUrlPrefix: 'https://i18n.selly.ch/seller-cockpit/',
  inputPrefix: '%-%tolgee:',
  inputSuffix: '%-%',
  defaultLanguage: 'de',
  enableLanguageStore: true,
  enableLanguageDetection: true,
  tagAttributes: {
    textarea: ['placeholder'],
    input: [
      'value',
      'placeholder',
    ],
    img: ['alt'],
    '*': [
      'aria-label',
      'title',
    ],
  },
  highlightKeys: [0],
  passToParent: [
    'option',
    'optgroup',
  ],
  restrictedElements: [
    'script',
    'style',
  ],
  highlightColor: 'rgb(0, 255, 0)',
  highlightWidth: 7,
  wrapperMode: 'invisible',
  preloadFallback: false,
  fallbackLanguage: 'de',
  watch: true,
};

withTolgee(i18n, config).use(ICU).use(initReactI18next).init();
