import logo from './agent_selly_logo_full.svg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaUserCircle } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { VscChromeClose } from 'react-icons/vsc';

import './AppHeader.css';

import { Revealer } from './Revealer';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { getValueFromCookie } from '../utils/getValueFromCookie';
import { useTranslation } from 'react-i18next';

type AppHeaderProps = {
  onBurgerMenuChange?: Function;
  burgerMenuOpen?: boolean;
  devTool?: ReactNode;
  showOnlyLogo?: boolean;
};

const allCockpitLanguages = [
  'DE',
  'FR',
  'EN',
];

export function AppHeader({
  devTool = null,
  onBurgerMenuChange = () => {},
  burgerMenuOpen,
  showOnlyLogo,
}: AppHeaderProps) {
  const [
    userRevealerOpen,
    setUserRevealerOpen,
  ] = useState(false);
  const [
    languageRevealerOpen,
    setLanguageRevealerOpen,
  ] = useState(false);
  const { i18n } = useTranslation();
  const [
    language,
    setLanguage,
  ] = useState({ de: 'DE', fr: 'FR', en: 'EN' }[i18n.language] ?? 'DE');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.toLowerCase());
    }
  }, [
    i18n,
    language,
  ]);

  const burgerIcon = (
    <div
      onClick={() => {
        onBurgerMenuChange(!burgerMenuOpen);
      }}
      className={'mt-2 ' + (burgerMenuOpen ? 'transform rotate-90' : '')}
    >
      <GiHamburgerMenu></GiHamburgerMenu>
    </div>
  );

  const logoutButton = (
    <div className="flex flex-row">
      <div className="text-2xl">
        <FaUserCircle />
      </div>
      <div>
        <VscChromeClose
          className={'mt-1 ml-2 mr-4' + (userRevealerOpen ? '' : ' text-white')}
        />
      </div>
    </div>
  );

  const languageSwitch = (
    <div className="flex flex-row">
      {language}
      <div>
        {languageRevealerOpen ? (
          <FiChevronUp className="mt-1 ml-2" />
        ) : (
          <FiChevronDown className="mt-1 ml-2" />
        )}
      </div>
    </div>
  );

  const languageSubmenuPanel = (
    <div>
      {allCockpitLanguages.map((lang) => (
        <div
          key={lang}
          onClick={() => {
            setLanguage(lang);
          }}
          className="cursor-pointer"
        >
          {lang}
        </div>
      ))}
    </div>
  );

  const logoutSubmenuPanel = (
    <div
      onClick={() => {
        logout();
        setUserRevealerOpen(false);
      }}
    >
      <div>{window.location.pathname === '/login' ? 'Login' : 'Logout'}</div>
    </div>
  );

  return (
    <>
      <header
        style={{ maxWidth: '1440px' }}
        className="AppHeader py-6 mx-auto"
      >
        {devTool}
        <div className="grid grid-cols-12">
          <div className="col-start-2  w-56">
            <img
              src={logo}
              className="HeaderLogo"
              alt="logo"
            />
          </div>
          {showOnlyLogo ? null : (
            <div className="col-end-12 flex">
              <div className="">
                <Revealer
                  revealed={languageRevealerOpen}
                  onRevealChange={(should: boolean) => {
                    setLanguageRevealerOpen(should);
                    if (should) {
                      setUserRevealerOpen(false);
                    }
                  }}
                  submenuPanel={languageSubmenuPanel}
                >
                  {languageSwitch}
                </Revealer>
              </div>
              <div className="hidden md:inline-block">
                <Revealer
                  revealed={userRevealerOpen}
                  onRevealChange={(should: boolean) => {
                    setUserRevealerOpen(should);
                    if (should) {
                      setLanguageRevealerOpen(false);
                    }
                  }}
                  submenuPanel={logoutSubmenuPanel}
                >
                  {logoutButton}
                </Revealer>
              </div>
              <div className="inline-block md:hidden">{burgerIcon}</div>
            </div>
          )}
        </div>
      </header>
    </>
  );
}

const logout = async () => {
  const authToken = getValueFromCookie('authToken') || '';
  const userId = getValueFromCookie('userId') || '';

  if (authToken === 'example' || userId === 'example') {
    document.cookie = `authToken=;Expires=${new Date(
      0,
    )};SameSite=Strict;secure;path=/`;
    document.cookie = `userId=;Expires=${new Date(
      0,
    )};SameSite=Strict;secure;path=/`;

    window.location.replace('/login/');

    return;
  }

  const response = await fetch(`${process.env.REACT_APP_SERVER_HOST}/logout`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-Auth-Token': authToken,
      'X-User-Id': userId,
    },
  });

  if (!response.ok) {
    console.error('Problem logging out', response.status, response.statusText);
  }

  window.location.replace('/login/');
};
