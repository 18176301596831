import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { t } = useTranslation();
  const [
    loginForm,
    setLoginForm,
  ] = useState({ email: location?.state?.email || '' });
  const [
    status,
    setStatus,
  ] = useState('idle');

  useEffect(() => {
    const resetPasswordWithToken = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_HOST}/resetPasswordWithToken`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: loginForm.email }),
          },
        );
        return response.json();
      } catch (e) {
        setStatus('error');
      }
    };
    if (status === 'fetching') {
      resetPasswordWithToken().then((result) => {
        if (result) {
          result?.emailSent ? setStatus('emailSent') : setStatus('error');
        }
      });
    }
  }, [
    status,
    loginForm,
    history,
  ]);

  return (
    <>
      <main className="flex w-full h-full">
        <div className="mx-auto pt-12">
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              setStatus('fetching');
            }}
            id="login-form"
          >
            <h1 className="text-5xl leading-tight">{t('login_reset_title')}</h1>
            <p className="relative py-4">{t('login_reset_description')}</p>

            <p className="py-5">
              <input
                placeholder={t('login_placeholder_email_text')}
                onChange={(e) => {
                  setStatus('idle');
                  setLoginForm((state) => ({
                    ...state,
                    email: e.target.value,
                  }));
                }}
                value={loginForm.email}
                className="border border-gray w-full h-12 pl-2"
                type="email"
                name="email"
                autoFocus={loginForm.email === ''}
              />
            </p>

            {status === 'error' && (
              <p className="relative pb-4">
                {t('login_reset_state_error_message')}
              </p>
            )}

            {status === 'emailSent' && (
              <p className="relative pb-4">
                Sie erhalten eine E-Mail mit einem Link zum Zurücksetzen Ihres
                Passworts.
              </p>
            )}
            <button
              className={`border w-96 h-12 text-xl ${
                !loginForm.email || status === 'emailSent'
                  ? 'cursor-not-allowed'
                  : 'bg-blue text-white'
              }`}
              type="submit"
            >
              {t('login_reset_button')}
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default withRouter(ResetPassword as any);
