import {
  Bar,
  ComposedChart,
  Dot,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import formatMoney from 'utils/formatMoney';
import { formatedDate } from 'utils/formatDate';
import { useTranslation } from 'react-i18next';

/*type CustomTooltipProps = {
    active : boolean,
    payload: any[],
    label: string
}*/

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="border-2 p-2 bg-white"
        style={{
          borderColor: '#55D5C6',
          boxShadow: '3px 3px 9px -1px #55D5C6',
        }}
      >
        <div className="font-bold">{formatMoney(payload[0].value)}</div>
        <div className="font-bold text-gray-400">
          {formatedDate(new Date(label))}
        </div>
      </div>
    );
  }
  return null;
};

type TimeSeriesChartProps = {
  chartData: any[];
  width?: number;
  height?: number;
  yDomain: [number, number];
  estimates: {
    upper: number;
    lower: number;
    salePrice: number;
  };
};
const TimeSeriesChart = ({
  estimates,
  yDomain,
  chartData,
  height = 284,
  width,
}: TimeSeriesChartProps) => {
  const { i18n } = useTranslation();
  const smallestUnit = chartData.reduce((ret, a) => {
    return a.date < ret ? a.date : ret;
  }, chartData[0]?.date);

  const largestUnit = chartData.reduce((ret, a) => {
    return a.date > ret ? a.date : ret;
  }, 0);
  const extraDays = 1000 * 60 * 60 * 24 * 3;

  const FixedComposedChart: any = ComposedChart;
  const FixedTooltip: any = Tooltip;
  const FixedDot: any = Dot;
  const FixedBar: any = Bar;
  const FixedScatter: any = Scatter;

  return (
    <ResponsiveContainer
      width={width || '100%'}
      height={height}
    >
      <FixedComposedChart data={chartData}>
        <XAxis
          dataKey="date"
          name="Time"
          tickFormatter={(unixTime) => {
            const d = new Date(unixTime);
            return d.toLocaleDateString(i18n.language, {
              day: 'numeric',
              month: 'short',
            });
          }}
          ticks={[
            smallestUnit - extraDays,
            (smallestUnit + largestUnit) / 2,
            largestUnit + extraDays,
          ]}
          domain={[
            smallestUnit - extraDays,
            largestUnit + extraDays,
          ]}
          type="number"
        />
        <YAxis
          dataKey="price"
          tickFormatter={(num) => {
            return Math.floor(num / 1000) + 'k';
          }}
          domain={yDomain}
          ticks={[
            yDomain[0],
            estimates.lower,
            estimates.salePrice,
            estimates.upper,
          ]}
          interval={0}
        />
        <FixedTooltip content={CustomTooltip} />

        <ReferenceLine y={estimates.lower} />
        <ReferenceLine y={estimates.upper} />
        <ReferenceLine y={estimates.salePrice} />

        <FixedScatter
          dataKey="price"
          shape={<FixedDot r={12}></FixedDot>}
          fill="#ebf9f7"
        />
        <FixedBar
          dataKey="price"
          fill="#55D5C6"
          barSize={5}
        />
        <FixedScatter
          dataKey="price"
          shape={<FixedDot r={6}></FixedDot>}
          fill="#55D5C6"
        />
        <FixedScatter
          dataKey="price"
          shape={<FixedDot r={2}></FixedDot>}
          fill="white"
        />
      </FixedComposedChart>
    </ResponsiveContainer>
  );
};

export default TimeSeriesChart;
