import type { Property } from 'api/apiTypes';
import { useTranslation } from 'react-i18next';

type NumberBlockProps = {
  title: String;
  number: Number;
  onClick: Function;
  type: string;
  colorClass: string;
  selectedStatType: string;
};

function NumberBlock({
  title,
  number,
  onClick,
  type,
  colorClass,
  selectedStatType,
}: NumberBlockProps) {
  return (
    <div
      className={
        'text-sm py-2 px-4 relative ' +
        (type !== 'none' ? 'cursor-pointer ' : '') +
        (selectedStatType === type ? 'as-bg-cyan' : colorClass)
      }
      onClick={() => {
        type !== 'none' && onClick(type);
      }}
      style={{ height: '100%', minHeight: '100px' }}
    >
      <div className="mb-6">{title}</div>
      <div className="text-2xl font-bold absolute bottom-1 right-2">{`${number}`}</div>
    </div>
  );
}

type NumbersProps = {
  property: Property | undefined;
  onStatTypeChange: Function;
  selectedStatType: string;
  fromDate?: Date;
  toDate?: Date;
};

export function Numbers({
  fromDate,
  toDate,
  property,
  onStatTypeChange,
  selectedStatType,
}: NumbersProps): JSX.Element {
  const { t } = useTranslation();

  if (!property) {
    return (
      <div>
        {t('dashboard_numbers_kein_objekt_geladen', 'Kein Objekt geladen')}
      </div>
    );
  }
  const { saleStartDate } = property;

  function selectStat(type: string) {
    onStatTypeChange(type);
  }

  const duringSelectedPeriod = getDateRangeChecker({
    fromDate,
    toDate,
    saleStartDate,
  });

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-4 md:grid-rows-2 gap-4 mb-8">
        <div>
          <NumberBlock
            selectedStatType={selectedStatType}
            colorClass="as-bg-green-light"
            type="slideshow"
            onClick={selectStat}
            title={t(
              'dashboard_numbers_ansichten_des_inserats',
              'Ansichten des Inserats',
            )}
            number={
              property.microActivities.filter(
                (act) =>
                  duringSelectedPeriod(act.createdAt) &&
                  act.type === 'slideshow',
              ).length
            }
          ></NumberBlock>
        </div>
        <div>
          <NumberBlock
            selectedStatType={selectedStatType}
            colorClass="as-bg-green-light"
            type="360tour"
            onClick={selectStat}
            title={t('dashboard_numbers_360-gradtouren', '360-GradTouren')}
            number={
              property.microActivities.filter(
                (act) =>
                  duringSelectedPeriod(act.createdAt) && act.type === '360tour',
              ).length
            }
          ></NumberBlock>
        </div>
        <div>
          <NumberBlock
            selectedStatType={selectedStatType}
            colorClass="as-bg-green-light"
            type="expose"
            onClick={selectStat}
            title={t('dashboard_numbers_exposé-downloads', 'Exposé-Downloads')}
            number={
              (property.exposes || []).filter((expose) => {
                return duringSelectedPeriod(new Date(expose.createdAt));
              }).length
            }
          ></NumberBlock>
        </div>
        <div className="row-span-2">
          <NumberBlock
            selectedStatType={selectedStatType}
            colorClass="as-bg-cyan-light"
            type="offers"
            onClick={selectStat}
            title={t(
              'dashboard_numbers_eingegangen_angebote',
              'Eingegangen Angebote',
            )}
            number={
              property.offers.filter((offer) =>
                duringSelectedPeriod(new Date(offer.date)),
              ).length
            }
          ></NumberBlock>
        </div>
        <div>
          <NumberBlock
            selectedStatType={selectedStatType}
            colorClass="as-bg-blue-light-no-hover"
            type="none"
            onClick={selectStat}
            title={t(
              'dashboard_numbers_besichtigungen_gebucht',
              'Besichtigungen gebucht',
            )}
            number={
              property.calendarEvents.filter(
                (visit) =>
                  getDateRangeChecker({
                    fromDate,
                    saleStartDate,
                    inFuture: true,
                  })(new Date(visit.date)) && visit.booked,
              ).length
            }
          ></NumberBlock>
        </div>
        <div>
          <NumberBlock
            selectedStatType={selectedStatType}
            colorClass="as-bg-blue-light-no-hover"
            type="none"
            onClick={selectStat}
            title={t('dashboard_numbers_interessenten', 'Interessenten')}
            number={property.statistics.totalInterestedBuyers}
          ></NumberBlock>
        </div>
        <div>
          <NumberBlock
            type="notinterested"
            selectedStatType={selectedStatType}
            colorClass="as-bg-blue-light"
            onClick={selectStat}
            title={t(
              'dashboard_numbers_nicht_mehr_interessierte',
              'Nicht mehr Interessierte',
            )}
            number={property.statistics.totalUninterestedBuyers}
          ></NumberBlock>
        </div>
      </div>
    </>
  );
}

function getDateRangeChecker({
  fromDate,
  saleStartDate,
  toDate,
  inFuture = false,
}: {
  fromDate: Date | undefined;
  saleStartDate: Date;
  toDate?: Date | undefined;
  inFuture?: boolean;
}) {
  return (dateToCompare: Date) =>
    dateToCompare > (fromDate || saleStartDate) && inFuture
      ? inFuture
      : dateToCompare < (toDate || new Date());
}
