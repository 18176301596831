import type { UserQueryResult } from '../components/DevTool/DevTool';
import { getValueFromCookie } from '../utils/getValueFromCookie';
import { exampleDataUsers } from '../data/exampleDataUsers';

export const fetchActiveUsers = async (): Promise<UserQueryResult> => {
  const authToken = getValueFromCookie('authToken') || '';
  const userId = getValueFromCookie('userId') || '';

  if (authToken === 'example' || userId === 'example') {
    return {
      success: true,
      result: 'success',
      users: exampleDataUsers,
    };
  }

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_HOST}/cockpitUsers`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
        'X-User-Id': userId,
      },
    },
  );

  if (!response.ok) {
    throw new Error('Problem fetching data');
  }

  return response.json();
};
