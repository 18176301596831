import type { Offer, Property } from 'api/apiTypes';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Clippy } from 'components/Dashboard/Clippy';
import { OffersDiagram } from 'components/Dashboard/Diagrams/OffersDiagram';
import { Notes } from 'components/Dashboard/Notes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from 'utils/formatMoney';
import { DetailPageLayout } from '../DetailPageLayout';
import { assetUrl } from '../../utils/assetUrl';

type OfferBlockProps = {
  offer: Offer;
  small?: boolean;
};

function OfferBlock({ offer, small }: OfferBlockProps): JSX.Element {
  const [
    revealed,
    setRevealed,
  ] = useState(!small);
  const { t } = useTranslation();

  return (
    <div
      onClick={() => {
        setRevealed(!revealed);
      }}
      className="md:border-2 md:py-4 relative cursor-pointer"
      key={'' + offer.date + offer.price + offer.firstname + offer.message}
    >
      <div className="md:text-lg">
        <div className="grid grid-cols-12">
          <div className="col-span-4 md:pl-4">{formatMoney(offer.price)}</div>
          <div className="col-span-3 md:pl-2">
            {offer.date.getDate()}.{offer.date.getMonth() + 1}.
            {offer.date.getFullYear()}
          </div>
          <div className="col-span-5 md:pl-2">
            {offer.firstname} {offer.lastname}
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        {(!revealed || (revealed && small)) && (
          <div className="absolute right-6 top-6">
            {revealed ? 'X' : <BsChevronDown />}
          </div>
        )}
      </div>
      {revealed && (
        <div className="md:pl-4">
          <hr className="mt-4 mb-4 md:mt-4 md:mb-6" />
          <div className="text-xs text-gray md:pb-1">
            {t('offers_offers_persönliche_nachricht', 'Persönliche Nachricht')}
          </div>
          <div className="mb-2">
            {offer.message === 'no message'
              ? t('offers_no_message_text', 'Keine Nachricht')
              : offer.message}
          </div>
          <div className="mb-6 pr-4">
            <div className="float-right">
              <a
                className="as-link"
                href={assetUrl(offer.financialProofUrl)}
              >
                {t(
                  'offers_confirmation_of_financing_link_text',
                  'Finanzierungsbestätigung',
                )}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

type OffersProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
  selectedProperty: Property;
};

enum SortTypes {
  priceDesc = 'priceDesc',
  priceAsc = 'priceAsc',
  dateAsc = 'dateAsc',
  dateDesc = 'dateDesc',
}

export function Offers({
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
  selectedProperty,
}: OffersProps): JSX.Element {
  const { t } = useTranslation();
  const headerContent = <h4>{t('offers_title')}</h4>;
  const [
    sort,
    setSort,
  ] = useState<SortTypes>(SortTypes.priceDesc);
  const rightContent = (
    <div>
      <Notes property={selectedProperty}></Notes>
      <Clippy></Clippy>
    </div>
  );

  return !selectedProperty ? (
    <div>loading...</div>
  ) : (
    <DetailPageLayout
      {...{ selectedPropertyId, setSelectedPropertyId, properties }}
      headerContent={headerContent}
      rightContent={rightContent}
    >
      <div>
        <div className="mb-10">
          <OffersDiagram
            filteredOffers={selectedProperty.offers}
            salePrice={selectedProperty.salePrice}
            upperEstimatePrice={selectedProperty.upperEstimatePrice}
            lowerEstimatePrice={selectedProperty.lowerEstimatePrice}
          ></OffersDiagram>
        </div>

        <div className="flex flex-col text-xs md:grid md:grid-cols-3x2-1 md:gap-4 mb-4 md:mb-6 pl-6 md:pl-0">
          <div className="flex flex-row md:flex-col mb-4 gap-4 md:gap-0">
            <div>{formatMoney(selectedProperty.salePrice)}</div>
            <div>{t('offers_detail_target_price_text')}</div>
          </div>
          <div className="flex flex-row md:flex-col mb-4 gap-4 md:gap-0">
            <div>
              {selectedProperty.lowerEstimatePrice
                ? formatMoney(selectedProperty.lowerEstimatePrice)
                : t('offers_detail_no_data_text')}
            </div>
            <div>{t('offers_detail_lower_bank_estimate_text')}</div>
          </div>
          <div className="flex flex-row md:flex-col mb-4 gap-4 md:gap-0">
            <div>
              {selectedProperty.upperEstimatePrice
                ? formatMoney(selectedProperty.upperEstimatePrice)
                : t('offers_detail_no_data_text')}
            </div>
            <div>{t('offers_detail_upper_bank_estimate_text')}</div>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-2 text-gray-300 text-xs font-bold">
          <div
            onClick={() =>
              setSort((sort) => {
                return sort === SortTypes.priceDesc
                  ? SortTypes.priceAsc
                  : SortTypes.priceDesc;
              })
            }
            className={`text-gray flex justify-between col-span-4 border-b-2 ${
              sort === SortTypes.priceDesc || sort === SortTypes.priceAsc
                ? 'as-color-blue  border-blue-500'
                : ''
            } pr-2 cursor-pointer`}
          >
            {t('offers_detail_table_price_title')}
            {sort === SortTypes.priceAsc ? <BsChevronUp /> : <BsChevronDown />}
          </div>
          <div
            onClick={() =>
              setSort((sort) => {
                return sort === SortTypes.dateDesc
                  ? SortTypes.dateAsc
                  : SortTypes.dateDesc;
              })
            }
            className={`text-gray flex justify-between ${
              sort === SortTypes.dateAsc || sort === SortTypes.dateDesc
                ? 'as-color-blue  border-blue-500'
                : ''
            } col-span-3 px-2 border-l-2 border-b-2 cursor-pointer`}
          >
            {t('offers_detail_table_date_title')}{' '}
            {sort === SortTypes.dateAsc ? <BsChevronUp /> : <BsChevronDown />}
          </div>
          <div className="text-gray col-span-5 pl-2 border-l-2 border-b-2">
            {t('offers_detail_table_pos_buyer_title')}
          </div>
        </div>
        <br />
        <div className="grid gap-4">
          {selectedProperty.offers
            .sort((offer1, offer2) => {
              if (offer1 && offer2) {
                return sort === SortTypes.priceDesc
                  ? offer2.price - offer1.price
                  : sort === SortTypes.priceAsc
                  ? offer1.price - offer2.price
                  : sort === SortTypes.dateDesc
                  ? offer2.date.getTime() - offer1.date.getTime()
                  : offer1.date.getTime() - offer2.date.getTime();
              }
              return 1;
            })
            .map((offer) => {
              return (
                <OfferBlock
                  offer={offer}
                  small
                />
              );
            })}
        </div>
      </div>
    </DetailPageLayout>
  );
}
