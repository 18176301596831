import type { Offer } from 'api/apiTypes';
import TimeSeriesChart from './TimeSeriesChart';

type OffersDiagramProps = {
  filteredOffers: Offer[];
  salePrice: number;
  lowerEstimatePrice: number;
  upperEstimatePrice: number;
};

export function OffersDiagram({
  filteredOffers,
  salePrice,
  lowerEstimatePrice,
  upperEstimatePrice,
}: OffersDiagramProps): JSX.Element | null {
  const data =
    filteredOffers
      .sort((offer1, offer2) => offer1.price - offer2.price)
      .map((offer) => {
        return {
          date: offer.date.getTime(),
          price: offer.price,
        };
      }) || [];

  return !data.length ? null : (
    <div className="mb-8 w-full">
      <TimeSeriesChart
        yDomain={[
          Math.min(
            Math.round(lowerEstimatePrice * 0.9),
            data[0].price * 0.9,
            salePrice * 0.9,
          ),
          Math.max(
            Math.round(upperEstimatePrice * 1.1),
            data.slice(-1)[0].price * 1.1,
            salePrice * 1.1,
          ),
        ]}
        estimates={{
          upper: upperEstimatePrice,
          lower: lowerEstimatePrice,
          salePrice: salePrice,
        }}
        chartData={data}
      ></TimeSeriesChart>
    </div>
  );
}
