import type { Property } from 'api/apiTypes';
import { useTranslation } from 'react-i18next';

type MultipropertySwitcherProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Property[];
};

export function MultipropertySwitcher({
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
}: MultipropertySwitcherProps): JSX.Element | null {
  const { t } = useTranslation();
  const propertiesData: Property[] = Object.values(properties);

  return propertiesData.length > 1 ? (
    <div className="border-b-2 mb-4">
      {propertiesData.map((data, i) => {
        return (
          <div
            key={i + data.street}
            style={{ borderColor: '#468CD3', minWidth: '7em' }}
            className={
              'font-bold inline-block mr-4 cursor-pointer' +
              (selectedPropertyId === i ? ' border-b-2 as-color-blue' : '')
            }
            onClick={() => {
              setSelectedPropertyId(i);
            }}
          >
            {t('multi_property_switcher_objekt', 'Objekt')} {i + 1}
          </div>
        );
      })}
    </div>
  ) : null;
}
