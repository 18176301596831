interface Options {
  readonly secure?: boolean;
  readonly prefix?: string;
  readonly postfix?: string;
}

export function assetUrl(
  path: string | unknown,
  options: Options = {},
): string {
  if (!path || typeof path !== 'string') {
    return '/error';
  }

  if (path.includes('://')) {
    return path;
  }

  if (path.startsWith('/example')) {
    return path;
  }

  let server: string = '';

  if (options.secure) {
    server = process.env.REACT_APP_SECURE_ASSET_SERVER || '';
  } else {
    server = process.env.REACT_APP_ASSET_SERVER || '';
  }

  let newPath = path;

  if (options.prefix) {
    newPath = `${options.prefix}${newPath}`;
  }

  if (options.postfix) {
    newPath = `${newPath}${options.postfix}`;
  }

  return `${server}${newPath}`;
}
