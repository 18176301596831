import { useTranslation } from 'react-i18next';

export function Clippy(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div style={{ background: '#DDD' }}>
      <div className="p-4">
        <div className="font-bold mb-2">{t('sidebar_extra_title')}</div>
        <div className="mb-4">{t('sidebar_extra_text')}</div>
        <a
          href={`${process.env.REACT_APP_WEBSITE}/ratgeber/artikel/grundstueckgewinnsteuer/`}
          className="as-link"
        >
          {t('sidebar_extra_link_text')}
        </a>
      </div>
      <img
        className="w-full"
        alt="Ratgeber"
        src="https://www.agentselly.ch/fileadmin/_processed_/f/f/csm_grundstueckgewinnsteuer_fb179e4067.jpg"
      />
    </div>
  );
}
