import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, withRouter } from 'react-router-dom';

const NewPassword = () => {
  const history = useHistory();
  const params = useParams<{ token: string }>();
  const { t } = useTranslation();
  const [
    loginForm,
    setLoginForm,
  ] = useState({ password: '' });
  const [
    status,
    setStatus,
  ] = useState('idle');

  useEffect(() => {
    const setPasswordWithToken = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_HOST}/setPasswordWithToken`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: params?.token,
              password: loginForm.password,
            }),
          },
        );
        return response.json();
      } catch (e) {
        setStatus('error');
      }
    };
    if (status === 'fetching') {
      setPasswordWithToken().then((result) => {
        console.log(result.result);
        switch (result.result) {
          case 'success':
            history.push('/login');
            break;
          case 'token expired':
            setStatus('token expired');
            break;
          default:
            setStatus('error');
            break;
        }
      });
    }
  }, [
    status,
    loginForm,
    history,
    params?.token,
  ]);

  return (
    <>
      <main className="flex w-full h-full">
        <div className="mx-auto pt-12">
          <>
            <form
              onSubmit={async (event) => {
                event.preventDefault();
                setStatus('fetching');
              }}
              id="login-form"
            >
              <h1 className="text-5xl leading-tight">
                {t('login_new_password_title')}
              </h1>
              <p className="relative py-4">
                {t('login_new_password_description')}
              </p>
              <p className="relative pb-4">
                <input
                  placeholder={t('login_placeholder_new_password_text')}
                  onChange={(e) =>
                    setLoginForm((state) => ({
                      ...state,
                      password: e.target.value,
                    }))
                  }
                  value={loginForm.password}
                  className="border w-96 h-12 pl-2"
                  min={8}
                  type="password"
                  name="password"
                  autoFocus={loginForm.password === ''}
                />
              </p>

              {status === 'error' && (
                <p className="relative py-4 text-red-500">
                  {t('login_new_password_error_message')}
                </p>
              )}

              {status === 'token expired' && (
                <p className="relative py-4 text-red-500">
                  Dieser Link ist nicht mehr gültig. Bitte setzen Sie das
                  Passwort wieder zurück.
                </p>
              )}

              {loginForm.password.length < 8 || !loginForm.password ? (
                <p className="relative pb-4">
                  {t('login_new_password_minimum_length_text')}
                </p>
              ) : status === 'token expired' ? (
                <button
                  onClick={() => history.push('/reset-password')}
                  className="border w-96 bg-blue h-12 text-white text-xl"
                >
                  {t('login_new_password_reset_button_text')}
                </button>
              ) : (
                <button
                  className="border w-96 bg-blue h-12 text-white text-xl"
                  type="submit"
                >
                  {t('login_new_password_button_text')}
                </button>
              )}
            </form>
          </>
        </div>
      </main>
    </>
  );
};

export default withRouter(NewPassword as any);
