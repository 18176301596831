import type { Activity, Property } from 'api/apiTypes';
import { BsChevronDown } from 'react-icons/bs';
import { DetailPageLayout } from 'components/DetailPageLayout';
import { Clippy } from '../Dashboard/Clippy';
import { Notes } from '../Dashboard/Notes';
import {
  ACTIVITY_TYPES,
  ActivityView,
  aggregateActivities,
} from '../Dashboard/DashboardActivities';
import { useEffect, useMemo, useState } from 'react';

import { formatDate } from '../../utils/formatDate';
import type { ManipulateType } from 'dayjs';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type ActivityDayBlockProps = {
  date: Date;
  activities: Activity[][];
  small?: boolean;
  openInitially?: boolean;
};

function ActivityDayBlock({
  openInitially,
  date,
  activities = [],
  small,
}: ActivityDayBlockProps): JSX.Element {
  const [
    revealed,
    setRevealed,
  ] = useState(!small || openInitially);
  const { i18n } = useTranslation();

  return (
    <>
      <div
        onClick={() => setRevealed(!revealed)}
        className="border-2 py-5 px-6 relative grid gap-4"
        key={'' + date}
      >
        <h5 className="text-lg font-bold">
          {formatDate(date)} -{' '}
          {date.toLocaleDateString(i18n.language, { weekday: 'long' })}
        </h5>
        {revealed && (
          <div className="grid gap-2 border-t">
            {activities.map((a) => {
              return (
                <ActivityView
                  count={a.length}
                  date={date}
                  type={ACTIVITY_TYPES[a[0].type]}
                  fullmode={true}
                  key={date + a[0].type}
                />
              );
            })}
          </div>
        )}

        {small && (
          <div
            onClick={() => {
              setRevealed(!revealed);
            }}
            className="absolute right-6 top-6"
          >
            {revealed ? 'X' : <BsChevronDown />}
          </div>
        )}
      </div>
    </>
  );
}

type ActivitiesProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
  selectedProperty?: Property;
};

export type AggrActi = {
  count: number;
  date: Date;
  type: string;
}[];

export function Activities(props: ActivitiesProps) {
  if (!props.selectedProperty) {
    return null;
  }
  return (
    <ActivitiesInner
      selectedProperty={props.selectedProperty}
      setSelectedPropertyId={props.setSelectedPropertyId}
      properties={props.properties}
      selectedPropertyId={props.selectedPropertyId}
    ></ActivitiesInner>
  );
}

type ActivitiesInnerProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
  selectedProperty: Property;
};

function ActivitiesInner({
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
  selectedProperty,
}: ActivitiesInnerProps): JSX.Element {
  const [
    selectedPastMonth,
    setSelectedPastMonth,
  ] = useState<string>('');
  const { t, i18n } = useTranslation();

  const aggregatedActivities = useMemo(
    () => aggregateActivities(selectedProperty.activities),
    [selectedProperty.activities],
  );

  const dates = useMemo(
    getListOfDatesFromDateUntilNow({
      startDate:
        selectedProperty?.activities.slice(-1)[0].createdAt ||
        selectedProperty?.saleStartDate,
      unit: 'days',
    }),
    [[selectedProperty.saleStartDate]],
  );

  const oneDateInEveryMonthSinceStart = useMemo(
    getListOfDatesFromDateUntilNow({
      startDate:
        selectedProperty?.activities.slice(-1)[0].createdAt ||
        selectedProperty.saleStartDate,
      unit: 'months',
    }),
    [selectedProperty.saleStartDate],
  );

  useEffect(() => {
    if (!selectedPastMonth) {
      setSelectedPastMonth(
        oneDateInEveryMonthSinceStart
          .sort((a, b) => b.getTime() - a.getTime())[0]
          .toISOString(),
      );
    }
  }, [
    oneDateInEveryMonthSinceStart,
    selectedPastMonth,
  ]);

  const rightContent = (
    <div>
      <Notes property={selectedProperty}></Notes>
      <Clippy></Clippy>
    </div>
  );

  return (
    <DetailPageLayout
      {...{ selectedPropertyId, setSelectedPropertyId, properties }}
      headerContent={<h4>{t('activities_title')}</h4>}
      rightContent={rightContent}
    >
      <div className="md:mr-4">
        <div className="mb-10 grid gap-4">
          {dates.map((date) => {
            const isCurrentMonth =
              date > dayjs(new Date()).startOf('month').toDate();
            const activityForDay = aggregatedActivities[formatDate(date)];
            if (activityForDay && isCurrentMonth) {
              return (
                <ActivityDayBlock
                  key={formatDate(date)}
                  date={date}
                  activities={Object.values(activityForDay)}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <div className="text-center">
          <h5 className="pb-2 text-lg font-bold">{t('activities_subtitle')}</h5>
          <select
            name="select-month"
            onChange={(e) => setSelectedPastMonth(e.target.value)}
            id="select-month"
            className="text-lg"
            value={selectedPastMonth}
          >
            {oneDateInEveryMonthSinceStart
              .sort((a, b) => b.getTime() - a.getTime())
              .map((date) => {
                return (
                  <option value={date.toISOString()}>
                    {date.toLocaleDateString(i18n.language, {
                      month: 'short',
                      year: 'numeric',
                    })}
                  </option>
                );
              })}
          </select>
        </div>
        <br />
        <div className="grid gap-4">
          {dates
            .filter((date) => {
              const isSelectedMonth = dayjs(date).isSame(
                dayjs(selectedPastMonth),
                'month',
              );
              const activityForDay = aggregatedActivities[formatDate(date)];
              return isSelectedMonth && activityForDay;
            })
            .map((date, i) => {
              return (
                <ActivityDayBlock
                  openInitially={i === 0 || i === 1}
                  small={true}
                  key={date.toISOString()}
                  date={date}
                  activities={Object.values(
                    aggregatedActivities[formatDate(date)],
                  )}
                ></ActivityDayBlock>
              );
            })}
        </div>
      </div>
    </DetailPageLayout>
  );
}

function getListOfDatesFromDateUntilNow({
  sort = 'desc',
  startDate,
  unit,
}: {
  sort?: string;
  startDate: Date;
  unit?: ManipulateType;
}): () => Date[] {
  return () =>
    new Array(dayjs(new Date()).diff(startDate, unit) + 1)
      .fill(null)
      .map((_, i) => {
        return dayjs(startDate).add(i, unit).toDate();
      })
      .sort((a, b) => {
        if (sort === 'desc') {
          return b.getTime() - a.getTime();
        } else {
          return a.getTime() - b.getTime();
        }
      });
}
