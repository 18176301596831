import type { InterestedPerson } from 'api/apiTypes';
import { useMemo } from 'react';
import {
  Bar,
  ComposedChart,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

type BarChartDiagramProps = {
  interestedPeople: InterestedPerson[];
  type: string;
};

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export function BarChartDiagram({
  interestedPeople,
  type,
}: BarChartDiagramProps): JSX.Element | null {
  const { t } = useTranslation();

  const notInterestedPeople = useMemo(
    () =>
      interestedPeople.filter((person) => {
        return person.status === type && person.reason !== 'no-feedback';
      }),
    [
      interestedPeople,
      type,
    ],
  );
  const data = useMemo(() => {
    return Object.entries(
      groupBy(notInterestedPeople, (i) => i.reason || 'no-information'),
    ).map(
      ([
        name,
        notInterestedPeopleGrouped,
      ]) => {
        return {
          name: t(`reason_${name}`),
          value: notInterestedPeopleGrouped.length,
          label: `${Math.round(
            (notInterestedPeopleGrouped.length / notInterestedPeople.length) *
              100,
          )}%`,
        };
      },
    );
  }, [
    notInterestedPeople,
    t,
  ]);
  const maxValue = Math.max.apply(
    null,
    [0].concat(data.map(({ value }) => value)),
  );
  const numberOfNoFeedbacks = interestedPeople.filter(
    (person) => person.status === type && person.reason === 'no-feedback',
  ).length;
  if (!data?.length) {
    return null;
  }

  const FixedComposedChart: any = ComposedChart;
  const FixedBar: any = Bar;

  return (
    <ResponsiveContainer
      width="100%"
      height={436}
    >
      <FixedComposedChart data={data}>
        {numberOfNoFeedbacks > 0 ? (
          <text
            x={20}
            y={10}
            fill="black"
            textAnchor="left"
            dominantBaseline="left"
          >
            <tspan fontSize="14">
              {t('reason_description', { numberOfNoFeedbacks })},
            </tspan>
          </text>
        ) : null}
        {numberOfNoFeedbacks > 0 ? (
          <text
            x={20}
            y={25}
            fill="black"
            textAnchor="left"
            dominantBaseline="left"
          >
            <tspan fontSize="14">
              {t('reason_description_rest')}{' '}
              {data.reduce((acu, cur) => acu + cur.value, 0)}:
            </tspan>
          </text>
        ) : null}
        <XAxis
          dataKey="name"
          angle={90}
          dy={5}
          dx={5}
          interval={0}
          height={150}
          textAnchor="left"
        />
        {Array.from(Array(maxValue + 1), () => 0).map((_, i) => {
          return (
            <ReferenceLine
              y={i}
              stroke="#DDE1EE"
            />
          );
        })}
        <YAxis
          dataKey="value"
          axisLine={false}
          ticks={[0]}
          domain={[
            0,
            maxValue + 0.5,
          ]}
        />
        <FixedBar
          dataKey="value"
          fill="#C7E7FF"
        >
          <LabelList
            dataKey="label"
            position="top"
          ></LabelList>
        </FixedBar>
      </FixedComposedChart>
    </ResponsiveContainer>
  );
}
