import './Dashboard.css';

import { Clippy } from './Clippy';
import { ContactPersons } from './ContactPersons';
import { DashboardActivities } from './DashboardActivities';
import { DashboardContract } from './DashboardContract';
import { DetailPageLayout } from 'components/DetailPageLayout';
import { Notes } from './Notes';
import type { Property } from 'api/apiTypes';
import { StatisticsComponent } from './StatisticsComponent';
import { useTranslation } from 'react-i18next';

type DashboardProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
  selectedProperty?: Property;
};

export function Dashboard({
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
  selectedProperty,
}: DashboardProps): JSX.Element {
  const { t } = useTranslation();

  if (!selectedProperty) {
    return (
      <div>{t('dashboard_dashboard_wird_geladen', 'Wird geladen')}...</div>
    );
  }

  const rightContent = (
    <div>
      <DashboardActivities property={selectedProperty}></DashboardActivities>
      <Notes property={selectedProperty}></Notes>
      <Clippy></Clippy>
    </div>
  );

  return (
    <DetailPageLayout
      {...{ selectedPropertyId, setSelectedPropertyId, properties }}
      headerContent={
        <h4 className="text-2xl md:text-3xl">
          {t('dashboard_dashboard_guten_tag', 'Guten Tag')}{' '}
          {selectedProperty.seller?.firstname || ''}{' '}
          {selectedProperty.seller?.lastname || ''}
        </h4>
      }
      rightContent={rightContent}
    >
      <>
        <div className="Dashboard">
          <StatisticsComponent property={selectedProperty} />{' '}
        </div>

        <div className="flex flex-col md:grid md:grid-cols-8 md:mt-24">
          <div className="mb-4 md:mb-0 col-span-8">
            <div className="flex flex-col md:grid md:grid-cols-2">
              <div className="mb-4 md:mb-0">
                <DashboardContract
                  property={selectedProperty}
                ></DashboardContract>
              </div>
              <ContactPersons property={selectedProperty}></ContactPersons>
            </div>
          </div>
        </div>
      </>
    </DetailPageLayout>
  );
}
