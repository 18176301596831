const monthNames = [
  'Jan',
  'Feb',
  'Mrz',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
];

export function getMonthNames(t: Function) {
  return t
    ? monthNames.map((m) => {
        return t(m);
      })
    : monthNames;
}

export function formatedDate(d: Date) {
  return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
}

export function formatDate(d: Date | string = new Date()) {
  return dateFromatter.format(new Date(d));
}

const dateFromatter = new Intl.DateTimeFormat('de-CH');
