import { useQuery } from 'react-query';
import type { Property } from '../../api/apiTypes';
import { fetchActiveUsers } from '../../api/fetchActiveUsers';

type User = {
  firstname: string;
  lastname: string;
  _id: string;
};

export type UserQueryResult = {
  success: boolean;
  result: string;
  users: User[];
};

export type PropertyQueryResult = {
  success: boolean;
  result: string;
  properties: { properties: Property[]; assetAccessToken?: string };
  statusCode?: number;
};

export type Params = {
  queryKey: [string, { personId: string }];
};

type DevToolProps = {
  selectedPersonId: string;
  setSelectedPersonId: React.Dispatch<React.SetStateAction<string>>;
};

export const DevTool = ({
  selectedPersonId,
  setSelectedPersonId,
}: DevToolProps): JSX.Element | null => {
  const usersQuery = useQuery(['cockpitUsers'], fetchActiveUsers, {
    staleTime: 60 * 60 * 60,
  });

  if (usersQuery.status !== 'success' || !usersQuery.data?.users?.length) {
    return null;
  }

  return (
    <div className="relative">
      <select
        onChange={(e) => setSelectedPersonId(e.target.value)}
        name="users"
        id="user-select"
        className="absolute inset-x-1/2 w-48"
        defaultValue={selectedPersonId}
      >
        <option value="">--Please select an option--</option>
        {usersQuery.data.users
          .sort((a, b) => {
            const aName = a.firstname.toUpperCase();
            const bName = b.firstname.toUpperCase();
            if (aName < bName) {
              return -1;
            }
            if (aName > bName) {
              return 1;
            }
            return 0;
          })
          .map((user) => {
            return (
              <option value={user._id}>
                {user.firstname} {user.lastname}
              </option>
            );
          })}
      </select>
    </div>
  );
};
