type RevealerProps = {
  children: JSX.Element;
  submenuPanel: JSX.Element;
  onRevealChange: Function;
  revealed: boolean;
};

export function Revealer({
  children,
  submenuPanel,
  onRevealChange,
  revealed,
}: RevealerProps): JSX.Element {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        onRevealChange(!revealed);
      }}
    >
      <div className="p-2">{children}</div>
      <div
        className={
          (revealed
            ? 'block absolute bg-white p-4 border-2 pt-2 pb-2'
            : 'hidden') + ''
        }
      >
        {submenuPanel}
      </div>
    </div>
  );
}
