import type { Property } from 'api/apiTypes';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar';
import './Layout.css';

type LayoutProps = {
  children: JSX.Element | null;
  property?: Property;
  multipleProperties: boolean;
  selectedPropertyNr: number;
  devTool: ReactNode;
};

export function Layout({
  children,
  property,
  multipleProperties,
  selectedPropertyNr,
  devTool,
}: LayoutProps): JSX.Element {
  const [
    burgerMenuOpen,
    setBurgerMenuOpen,
  ] = useState(false);

  return (
    <>
      <AppHeader
        devTool={devTool}
        burgerMenuOpen={burgerMenuOpen}
        onBurgerMenuChange={setBurgerMenuOpen}
      />
      <div className="flex md:grid md:grid-cols-12 gap-4">
        {window.location.pathname !== '/login' && property ? (
          <AppSidebar
            burgerMenuOpen={burgerMenuOpen}
            setBurgerMenuOpen={setBurgerMenuOpen}
            property={property}
            multipleProperties={multipleProperties}
            selectedPropertyNr={selectedPropertyNr}
          />
        ) : null}
        <div className="w-full md:col-span-9 pl-4 md:pl-2 mb-28">
          {children}
        </div>
      </div>
    </>
  );
}
