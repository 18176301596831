import type { Property } from 'api/apiTypes';
import { useMemo, useState } from 'react';
import { Numbers } from './Numbers';
import { OffersDiagram } from './Diagrams/OffersDiagram';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import type { DayRange } from 'react-modern-calendar-datepicker';
import DatePicker from 'react-modern-calendar-datepicker';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';
import { LineChartDiagram } from './Diagrams/LineChartDiagramm';
import { BarChartDiagram } from './Diagrams/BarChartDiagram';

type DiagramBasedOnTypeProps = {
  property: Property | undefined;
  type: string;
  dateFrom: Date;
  dateTo: Date;
};

function DiagramBasedOnType({
  type,
  property,
  dateFrom,
  dateTo,
}: DiagramBasedOnTypeProps): JSX.Element | null {
  if (!property) {
    return null;
  }
  if (type === 'offers') {
    return property ? (
      <OffersDiagram
        filteredOffers={property.offers.filter((offer) => {
          const offerDate = new Date(offer.date);
          return offerDate < dateTo && offerDate > dateFrom;
        })}
        salePrice={property.salePrice}
        lowerEstimatePrice={property.lowerEstimatePrice}
        upperEstimatePrice={property.upperEstimatePrice}
      ></OffersDiagram>
    ) : null;
  } else if (type === 'notinterested') {
    return (
      <BarChartDiagram
        interestedPeople={property?.interestedPeople || []}
        type="notinterested"
      ></BarChartDiagram>
    );
  } else {
    return (
      <LineChartDiagram
        type={type}
        dateTo={dateTo}
        dateFrom={dateFrom}
        property={property}
      ></LineChartDiagram>
    );
  }
}

type StatisticsComponentProps = {
  property: Property;
};

export function StatisticsComponent({
  property,
}: StatisticsComponentProps): JSX.Element {
  const { t, i18n } = useTranslation();

  const [
    selectedStatType,
    setSelectedStatType,
  ] = useState('slideshow');
  const saleStartDate = useMemo(
    () => new Date(property.saleStartDate),
    [property.saleStartDate],
  );
  const saleStartDateDayRangeFormat = {
    day: saleStartDate.getDate(),
    month: saleStartDate.getMonth() + 1,
    year: saleStartDate.getFullYear(),
  };
  const toDateAsDateRangeFormat = {
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  const [
    dayRange,
    setDayRange,
  ] = useState<DayRange>({
    from: saleStartDateDayRangeFormat,
    to: toDateAsDateRangeFormat,
  });

  const fromDate = useMemo(() => {
    return dayRange.from
      ? new Date(dayRange.from.year, dayRange.from.month - 1, dayRange.from.day)
      : saleStartDate;
  }, [
    dayRange,
    saleStartDate,
  ]);

  const toDate = useMemo(() => {
    return dayRange.to
      ? new Date(
          dayRange.to.year,
          dayRange.to.month - 1,
          dayRange.to.day,
          23,
          59,
        )
      : saleStartDate;
  }, [
    dayRange,
    saleStartDate,
  ]);

  if (!property) {
    return (
      <div>
        {t(
          'dashboard_statistics_component_kein_objekt_geladen',
          'Kein Objekt geladen',
        )}
      </div>
    );
  }

  const formatedFromDate = fromDate.toLocaleDateString(i18n.language, {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
  const formatedToDate = toDate.toLocaleDateString(i18n.language, {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });

  const formatInputValue = () => {
    if (!dayRange.from || !dayRange.to) {
      return t('dashboard_statistics_select_period_text', 'Zeitraum auswählen');
    }
    return `${formatedFromDate} - ${formatedToDate}`;
  };

  return (
    <div
      className=""
      style={{ minHeight: '20em' }}
    >
      <div className="grid grid-cols-12">
        <div className="col-span-3">
          <div className="text-lg md:text-2xl font-bold">
            {t('dashboard_statistics_component_statistik', 'Statistik')}
          </div>
        </div>
        <div className="col-span-9 flex items-center">
          <DatePicker
            minimumDate={saleStartDateDayRangeFormat}
            maximumDate={toDateAsDateRangeFormat}
            value={dayRange}
            onChange={setDayRange}
            formatInputText={formatInputValue}
          />
          <div>
            <FiChevronDown></FiChevronDown>
          </div>
        </div>
      </div>
      <br />
      {dayRange.from && dayRange.to && (
        <>
          <Numbers
            property={property}
            onStatTypeChange={(type: string) => {
              setSelectedStatType(type);
            }}
            fromDate={fromDate}
            toDate={toDate}
            selectedStatType={selectedStatType}
          ></Numbers>
          <DiagramBasedOnType
            type={selectedStatType}
            property={property}
            dateFrom={fromDate}
            dateTo={toDate}
          />
        </>
      )}
    </div>
  );
}
