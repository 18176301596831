import { BsHouse } from 'react-icons/bs';
import { FiArrowDown, FiArrowUpRight } from 'react-icons/fi';
import type { Property } from 'api/apiTypes';
import { useTranslation } from 'react-i18next';
import { assetUrl } from '../utils/assetUrl';

type AppSidebarPropertyProps = {
  property: Property;
  multipleProperties: boolean;
  selectedPropertyNr: number;
};

export function AppSidebarProperty({
  property,
}: AppSidebarPropertyProps): JSX.Element {
  const { t } = useTranslation();

  return property ? (
    <div className="py-10 ">
      <div className="pl-2 flex flex-row gap-4 pb-8">
        <div
          className="text-2xl text-white flex flex-row"
          style={{ fontSize: '2.5em', width: '1em' }}
        >
          <span className="inline-block">
            <BsHouse className="p-2 rounded-full as-bg-blue" />
          </span>
        </div>
        <div className="text-sm font-bold">
          <div>
            {property.street} {property.no}
          </div>
          <div>
            {property.zip} {property.city}
          </div>
        </div>
      </div>

      <div className="sm:pl-2 pl-3 text-sm">
        <a
          className="as-link flex flex-row gap-2"
          href={`${process.env.REACT_APP_WEBSITE}/marktplatz/${property._id}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('app_sidebar_property_zum_inserat', 'Zum Inserat')}{' '}
          <FiArrowUpRight className="mt-1" />
        </a>

        <a
          className="as-link flex flex-row gap-2 pt-2"
          href={assetUrl(property?.documentationDownloadURL, {
            secure: true,
          })}
          target="_blank"
          rel="noreferrer"
        >
          {t(
            'app_sidebar_property_verkaufsdokumentation',
            'Verkaufsdokumentation',
          )}{' '}
          <FiArrowDown className="mt-1" />
        </a>
      </div>
    </div>
  ) : (
    <div className="p-4 pl-8">
      {t('app_sidebar_property_keine_objektdaten', 'Keine Objektdaten')}
    </div>
  );
}
