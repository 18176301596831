import { useTranslation } from 'react-i18next';
import { useHistory, useParams, withRouter } from 'react-router-dom';

const Welcome = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams<{ token: string }>();
  const token = params?.token;

  return (
    <>
      <main className="flex w-full h-full">
        <div className="mx-auto pt-12">
          <>
            <h1 className="text-5xl leading-tight">{t('welcome_title')}</h1>
            <h2 className="text-4xl leading-tight py-2">
              {t('welcome_subtitle')}
            </h2>
            <p className="relative pt-4 pb-2">
              {t('welcome_description_first')}
            </p>
            <p className="relative pb-2">{t('welcome_description_second')}</p>
            <p className="relative pb-2">{t('welcome_description_third')}</p>
            <p className="relative pb-2">{t('welcome_description_fourth')}</p>
            <button
              onClick={() => history.push(`/new-password/${token}`, { token })}
              className="border w-96 bg-blue h-12 text-white text-xl my-4"
            >
              {t('welcome_button_text')}
            </button>
          </>
        </div>
      </main>
    </>
  );
};

export default withRouter(Welcome as any);
