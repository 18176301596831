import type { Property } from 'api/apiTypes';
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type LineChartDiagramProps = {
  property: Property;
  type: string;
  dateFrom: Date;
  dateTo: Date;
};

export function LineChartDiagram({
  property,
  type,
}: LineChartDiagramProps): JSX.Element {
  const FixedComposedChart: any = ComposedChart;
  const FixedLine: any = Line;
  const FixedTooltip: any = Tooltip;

  return (
    <ResponsiveContainer height={400}>
      <FixedComposedChart
        data={property[
          type === 'slideshow'
            ? 'slideshowsPerDate'
            : type === '360tour'
            ? 'threeDToursPerDate'
            : 'exposeDownloadsPerDate'
        ]?.map((d) => ({
          ...d,
          dateFormatted: d.dateFormatted.split('.').slice(0, -1).join('.'),
        }))}
        margin={{ top: 30, right: 5, bottom: 5, left: 5 }}
      >
        <FixedLine
          dataKey="total"
          stroke="#9AE272"
          strokeWidth={2}
          dot={false}
          fill="#ebf9f7"
        />
        <FixedTooltip
          formatter={(value: number, name: string, props: unknown) => {
            return [
              value,
              'Total',
            ];
          }}
        ></FixedTooltip>
        <XAxis
          dataKey="dateFormatted"
          angle={90}
          dy={20}
          dx={6}
          height={100}
        />
        <YAxis
          dataKey="total"
          interval={0}
        />
      </FixedComposedChart>
    </ResponsiveContainer>
  );
}
