import { useEffect, useState } from 'react';
import { getCookieExpirationDate, LOAD_EXAMPLE_PATH } from './PropertyProvider';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { t } = useTranslation();
  const [
    loginForm,
    setLoginForm,
  ] = useState({ email: location?.state?.email || '', password: '' });
  const [
    status,
    setStatus,
  ] = useState('idle');

  useEffect(() => {
    const login = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_HOST}/login`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: loginForm.email,
              password: loginForm.password,
            }),
          },
        );
        return response.json();
      } catch (e) {
        setStatus('error');
      }
    };
    if (status === 'fetching') {
      login().then((result) => {
        if (result) {
          const { data } = result;
          if (data?.authToken && data.userId) {
            document.cookie = `authToken=${
              data.authToken
            };Expires=${getCookieExpirationDate()};SameSite=Strict;secure;path=/`;
            document.cookie = `userId=${
              data.userId
            };Expires=${getCookieExpirationDate()};SameSite=Strict;secure;path=/`;
            window.location.replace(`/dashboard/`);
            setStatus('idle');
          } else {
            setStatus('error');
          }
        }
      });
    }
  }, [
    status,
    loginForm,
  ]);

  return (
    <>
      <main className="flex w-full h-full">
        <div className="mx-auto pt-12">
          <h1 className="text-5xl leading-tight">{t('login_title')}</h1>
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              setStatus('fetching');
            }}
            id="login-form"
          >
            <p className="pt-16 pb-2">
              <input
                placeholder={t('login_placeholder_email_text')}
                onChange={(e) =>
                  setLoginForm((state) => ({ ...state, email: e.target.value }))
                }
                value={loginForm.email}
                className="border border-gray w-full h-12 pl-2"
                type="email"
                name="email"
                autoFocus={loginForm.email === ''}
              />
            </p>
            <p className="relative pb-4">
              <input
                placeholder={t('login_placeholder_password_text')}
                onChange={(e) =>
                  setLoginForm((state) => ({
                    ...state,
                    password: e.target.value,
                  }))
                }
                value={loginForm.password}
                className="border border-gray w-full h-12 pl-2"
                type="password"
                name="password"
              />
            </p>
            <p>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                type="submit"
              >
                {t('login_button_text')}
              </button>
            </p>
          </form>
          {status === 'error' ? (
            <span className="text-red-500">
              {t('login_state_error_message')}
            </span>
          ) : null}
          <div className="pt-7">
            <span
              onClick={() =>
                history.push('/reset-password', { email: loginForm.email })
              }
              className="text-sm text-blue cursor-pointer"
            >
              {t('login_reset_password_button_text')}
            </span>
          </div>

          <div className="mt-32">
            <form
              action={`/${LOAD_EXAMPLE_PATH}`}
              method="GET"
            >
              <p>
                <button
                  className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                  type="submit"
                >
                  {t('example_button_text')}
                </button>
              </p>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(Login as any);
