import type { Property } from 'api/apiTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type NotesProps = {
  property: Property | undefined;
};
export function Notes({ property }: NotesProps): JSX.Element {
  const location = useLocation();
  const { t } = useTranslation();
  const lsKey = 'as_cockpit_note_' + location.pathname + '_' + property?._id;

  const savedItem = window.localStorage.getItem(lsKey);

  const [
    content,
    setContent,
  ] = useState(savedItem || '');

  return (
    <div className="mb-4">
      <div
        className="p-2 font-bold"
        style={{ background: '#FBF3B1' }}
      >
        {t('dashboard_notes_notizen', 'Notizen')}
      </div>
      <textarea
        className="w-full pl-2 pr-2"
        style={{
          lineHeight: '32px',
          minHeight: '15em',
          backgroundImage:
            'linear-gradient(0deg, #c7c7c7 1.61%, #FFF 1.61%, #FFF 50%, #c7c7c7 50%, #c7c7c7 51.61%, #FFF 51.61%, #FFF 100%)',
          backgroundSize: '62.00px 62.00px',
        }}
        onChange={(evt) => {
          const newCont = evt.target.value;
          setContent(newCont);
          window.localStorage.setItem(lsKey, newCont);
        }}
      >
        {content}
      </textarea>
    </div>
  );
}
