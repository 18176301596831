import './App.css';
import './utils/i18n';

import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PropertyProvider from 'components/PropertyProvider';
import { Suspense } from 'react';

const queryClient = new QueryClient();

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <QueryClientProvider client={queryClient}>
        <div
          style={{ maxWidth: '1440px' }}
          className="mx-auto"
        >
          <PropertyProvider />
        </div>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
