import { Link } from 'react-router-dom';
import type { Activity, Property } from 'api/apiTypes';
import { FiArrowRight } from 'react-icons/fi';
import { AiOutlineDownload } from 'react-icons/ai';
import { ReactComponent as ActivityIcon } from '../../icons/Activities_Icon.svg';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { useMemo } from 'react';
import {
  Dates_Cancelled as DatesCancelled,
  Dates_Icon as DatesIcon,
  Email_Icon as EmailIcon,
  Offer_Icon as OfferIcon,
} from '../../icons';

const ACTIVITY_TYPES_ICONS: {
  [key in ACTIVITY_TYPES_ENUM]: JSX.Element;
} = {
  agent_admin_work: <ActivityIcon />,
  chat_buyer: <ActivityIcon />,
  chat_seller: <ActivityIcon />,
  meeting_buyer: <ActivityIcon />,
  meeting_seller: <ActivityIcon />,
  new_interest: <ActivityIcon />,
  lost_interest: <DatesCancelled />,
  mail: <EmailIcon />,
  offer_cancelled: <DatesCancelled />,
  offer: <OfferIcon />,
  expose: <AiOutlineDownload />,
  sent_searchsubscription_online: <ActivityIcon />,
  viewing: <DatesIcon />,
  visit_slot_cancelled: <DatesCancelled />,
  none: <ActivityIcon />,
};

export enum ACTIVITY_TYPES_ENUM {
  agent_admin_work = 'agent_admin_work',
  meeting_buyer = 'meeting_buyer',
  meeting_seller = 'meeting_seller',
  chat_buyer = 'chat_buyer',
  chat_seller = 'chat_seller',
  new_interest = 'new_interest',
  lost_interest = 'lost_interest',
  offer_cancelled = 'offer_cancelled',
  offer = 'offer',
  expose = 'expose',
  mail = 'mail',
  none = 'none',
  viewing = 'viewing',
  visit_slot_cancelled = 'visit_slot_cancelled',
  sent_searchsubscription_online = 'sent_searchsubscription_online',
}

export const ACTIVITY_TYPES: { [key: string]: ACTIVITY_TYPES_ENUM } = {
  agent_admin_work: ACTIVITY_TYPES_ENUM.agent_admin_work,
  agent_chat_buyer: ACTIVITY_TYPES_ENUM.chat_buyer,
  agent_chat_seller: ACTIVITY_TYPES_ENUM.chat_seller,
  agent_meeting_buyer: ACTIVITY_TYPES_ENUM.meeting_buyer,
  agent_meeting_seller: ACTIVITY_TYPES_ENUM.meeting_seller,
  agent_phone_buyer: ACTIVITY_TYPES_ENUM.chat_buyer,
  agent_phone_seller: ACTIVITY_TYPES_ENUM.chat_seller,
  interest: ACTIVITY_TYPES_ENUM.new_interest,
  lost_interest: ACTIVITY_TYPES_ENUM.lost_interest,
  mail: ACTIVITY_TYPES_ENUM.mail,
  mail_from_buyer_website: ACTIVITY_TYPES_ENUM.mail,
  mail_from_seller: ACTIVITY_TYPES_ENUM.mail,
  mail_to_buyer: ACTIVITY_TYPES_ENUM.mail,
  mail_to_seller: ACTIVITY_TYPES_ENUM.mail,
  offer_cancelled: ACTIVITY_TYPES_ENUM.offer_cancelled,
  offer_given: ACTIVITY_TYPES_ENUM.offer,
  property_expose_download: ACTIVITY_TYPES_ENUM.expose,
  reminder_72h_property_expose_download: ACTIVITY_TYPES_ENUM.mail,
  secureForm: ACTIVITY_TYPES_ENUM.none,
  secureform_accepted: ACTIVITY_TYPES_ENUM.none,
  secureform_checks_in_pin_correctly: ACTIVITY_TYPES_ENUM.none,
  secureform_sent_message: ACTIVITY_TYPES_ENUM.none,
  seller: ACTIVITY_TYPES_ENUM.none,
  sent_searchsubscription_online:
    ACTIVITY_TYPES_ENUM.sent_searchsubscription_online,
  visit_slot_booked: ACTIVITY_TYPES_ENUM.viewing,
  visit_slot_cancelled: ACTIVITY_TYPES_ENUM.visit_slot_cancelled,
};

type ActivityProps = {
  type: ACTIVITY_TYPES_ENUM;
  date: Date;
  fullmode?: boolean;
  count?: number;
  reason?: string;
};

function bgColorForActivityType(type: ACTIVITY_TYPES_ENUM) {
  switch (type) {
    case ACTIVITY_TYPES_ENUM.mail:
      return 'rgb(242, 255, 233, 0.6)';
    case ACTIVITY_TYPES_ENUM.viewing:
    case ACTIVITY_TYPES_ENUM.visit_slot_cancelled:
      return 'rgb(233, 245, 255, 0.6)';
    case ACTIVITY_TYPES_ENUM.offer:
      return 'rgba(235, 255, 251, 0.6)';
    default:
      return 'rgba(245, 246, 248, 0.6)';
  }
}

function textForActivityType(
  type: ACTIVITY_TYPES_ENUM,
  count: number,
  t: Function,
) {
  switch (type) {
    case ACTIVITY_TYPES_ENUM.mail:
      return t('dashboard_dashboard_activities_mail', 'Mail');
    case ACTIVITY_TYPES_ENUM.viewing:
      return t('dashboard_dashboard_activities_besichtigung', 'Besichtigung');
    case ACTIVITY_TYPES_ENUM.offer:
      return t(
        'dashboard_dashboard_activities_eingegangenes_angebot',
        'Eingegangenes Angebot',
      );
    case ACTIVITY_TYPES_ENUM.expose:
      return t(
        'dashboard_dashboard_activities_exposé_download',
        'Exposé Download',
      );
    case ACTIVITY_TYPES_ENUM.agent_admin_work:
      return t(
        'dashboard_dashboard_activities_anpassung_verkaufsprozess',
        'Anpassung Verkaufsprozess',
      );
    case ACTIVITY_TYPES_ENUM.meeting_buyer:
    case ACTIVITY_TYPES_ENUM.meeting_seller:
      return t(
        'dashboard_dashboard_activities_diverse_korrespondenz',
        'Diverse Korrespondenz',
      );
    case ACTIVITY_TYPES_ENUM.chat_buyer:
    case ACTIVITY_TYPES_ENUM.chat_seller:
      return t('dashboard_dashboard_activities_telefon', 'Telefon');
    case ACTIVITY_TYPES_ENUM.offer_cancelled:
      return t('dashboard_dashboard_activities_angebot', 'Angebot');
    case ACTIVITY_TYPES_ENUM.visit_slot_cancelled:
      return t(
        'dashboard_dashboard_activities_termin_abgesagt',
        'Termin abgesagt',
      );
    case ACTIVITY_TYPES_ENUM.new_interest:
    case ACTIVITY_TYPES_ENUM.lost_interest:
      return t(
        'dashboard_dashboard_activities_interessierte_person',
        'Interessierte Person',
      );
    case ACTIVITY_TYPES_ENUM.none:
      return count + ' ' + (count === 1)
        ? t('dashboard_dashboard_activities_neue_aktivität', 'neue Aktivität')
        : t(
            'dashboard_dashboard_activities_neue_aktivitäten',
            'neue Aktivitäten',
          );
    case ACTIVITY_TYPES_ENUM.sent_searchsubscription_online:
      return t('dashboard_dashboard_activities_suchabos', 'Suchabos');
    default:
      unreachable(type);
  }
}

export function descriptionForActivityType(
  type: ACTIVITY_TYPES_ENUM,
  count: number,
  t: Function,
  reason?: string,
) {
  switch (type) {
    case ACTIVITY_TYPES_ENUM.mail:
      return (
        count +
        ' ' +
        (count === 1
          ? t('dashboard_dashboard_activities_neues_e-mail', 'neues E-Mail')
          : t('dashboard_dashboard_activities_neue_e-mails', 'neue E-Mails'))
      );
    case ACTIVITY_TYPES_ENUM.viewing:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_neue_besichtigung',
              'neue Besichtigung',
            )
          : t(
              'dashboard_dashboard_activities_neue_besichtigungen',
              'neue Besichtigungen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.offer:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_neues_angebot_eingegangen',
              'neues Angebot eingegangen',
            )
          : t(
              'dashboard_dashboard_activities_neue_angebote_eingegangen',
              'neue Angebote eingegangen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.agent_admin_work:
      return (
        count +
        ' ' +
        (count === 1
          ? reason
          : t('dashboard_dashboard_activities_verschiedene', 'Verschiedene'))
      );
    case ACTIVITY_TYPES_ENUM.expose:
      return `${count} ${
        count === 1
          ? t(
              'dashboard_dashboard_activities_exposé_heruntergeladen',
              'Exposé heruntergeladen',
            )
          : t(
              'dashboard_dashboard_activities_exposés_heruntergeladen',
              'Exposés heruntergeladen',
            )
      }`;
    case ACTIVITY_TYPES_ENUM.chat_buyer:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_gespräch_mit_interessierter_person',
              'Gespräch mit interessierter Person',
            )
          : t(
              'dashboard_dashboard_activities_gespräche_mit_interessierten_personen',
              'Gespräche mit interessierten Personen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.lost_interest:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_person_hat_interesse_veloren',
              'Person hat Interesse veloren',
            )
          : t(
              'dashboard_dashboard_activities_personen_haben_interesse_verloren',
              'Personen haben Interesse verloren',
            ))
      );
    case ACTIVITY_TYPES_ENUM.meeting_buyer:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_treffen_mit_interessierter_person',
              'Treffen mit interessierter Person',
            )
          : t(
              'dashboard_dashboard_activities_treffen_mit_interessierten_personen',
              'Treffen mit interessierten Personen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.meeting_seller:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_treffen_mit_ihnen',
              'Treffen mit Ihnen',
            )
          : t(
              'dashboard_dashboard_activities_treffen_mit_ihnen',
              'Treffen mit Ihnen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.chat_seller:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_gespräch_mit_ihnen',
              'Gespräch mit Ihnen',
            )
          : t(
              'dashboard_dashboard_activities_gespräche_mit_ihnen',
              'Gespräche mit Ihnen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.offer_cancelled:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_angebot_zurückgezogen_oder_abgelehnt',
              'Angebot zurückgezogen oder abgelehnt',
            )
          : t(
              'dashboard_dashboard_activities_angebote_zurückgezogen_oder_abgelehnt',
              'Angebote zurückgezogen oder abgelehnt',
            ))
      );
    case ACTIVITY_TYPES_ENUM.visit_slot_cancelled:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_besichtigungstermin_abgesagt',
              'Besichtigungstermin abgesagt',
            )
          : t(
              'dashboard_dashboard_activities_besichtigungstermine_abgesagt',
              'Besichtigungstermine abgesagt',
            ))
      );
    case ACTIVITY_TYPES_ENUM.new_interest:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_neue_interessierte_person',
              'neue interessierte Person',
            )
          : t(
              'dashboard_dashboard_activities_neue_interessierte_personen',
              'neue interessierte Personen',
            ))
      );
    case ACTIVITY_TYPES_ENUM.sent_searchsubscription_online:
      return (
        count +
        ' ' +
        (count === 1
          ? t(
              'dashboard_dashboard_activities_mail_an_suchabonnent_verschickt',
              'Mail an Suchabonnent verschickt',
            )
          : t(
              'dashboard_dashboard_activities_mails_an_suchabonnente_verschickt',
              'Mails an Suchabonnente verschickt',
            ))
      );
    case ACTIVITY_TYPES_ENUM.none:
      return (
        count +
        ' ' +
        (count === 1
          ? t('dashboard_dashboard_activities_neue_aktivität', 'neue Aktivität')
          : t(
              'dashboard_dashboard_activities_neue_aktivitäten',
              'neue Aktivitäten',
            ))
      );
    default:
      unreachable(type);
  }
}

function linkForActivityType(type: ACTIVITY_TYPES_ENUM, t: any) {
  switch (type) {
    case ACTIVITY_TYPES_ENUM.viewing:
      return { to: '/dates', text: t('activities_extra_link_to_dates_text') };
    case ACTIVITY_TYPES_ENUM.offer:
      return { to: '/offers', text: t('activities_extra_link_to_offers_text') };
    default:
      return undefined;
  }
}

export function ActivityView({
  type,
  date,
  fullmode,
  count,
  reason,
}: ActivityProps) {
  const { t } = useTranslation();

  const bgColor = bgColorForActivityType(type);
  const text = textForActivityType(type, count || 0, t);
  const desc = descriptionForActivityType(type, count || 0, t, reason);
  const icon = ACTIVITY_TYPES_ICONS[type] || <ActivityIcon />;
  const link = fullmode && linkForActivityType(type, t);

  return (
    <div
      className="text-sm pb-2 pt-4 px-3"
      style={{ backgroundColor: bgColor }}
    >
      <div className={'flex flex-row gap-2'}>
        <div className="text-xl w-4">{icon}</div>
        <span className="font-bold">{text}</span>
      </div>
      <div className="flex pl-6 justify-between">
        <div className="flex w-full justify-between">
          <div>{desc}</div>
          {fullmode && link && (
            <div className="ml-auto">
              <Link
                className="as-link"
                to={link.to}
              >
                {link.text}
              </Link>
            </div>
          )}
        </div>
        {!fullmode && (
          <div className="text-xs bottom-1  right-2">{formatDate(date)}</div>
        )}
      </div>
    </div>
  );
}

type DashboardActivitiesProps = {
  property: Property;
};

// type AggregatedActivities = {
//   count: number
//   date: Date
//   type: string
// }[]

export function DashboardActivities({
  property,
}: DashboardActivitiesProps): JSX.Element {
  const { t } = useTranslation();

  const aggregatedActivities = useMemo(
    () =>
      Object.values(aggregateActivities(property.activities))
        .map((obj) => {
          return Object.values(obj).map((activityList) => ({
            count: activityList.length,
            date: activityList[0].createdAt,
            type: ACTIVITY_TYPES[activityList[0].type],
            reason:
              activityList.find((activity) => Boolean(activity.reason))
                ?.reason || '',
          }));
        })
        .flat()
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        .slice(0, 5),
    [property.activities],
  );

  return (
    <div
      className="mb-4"
      style={{ minHeight: '20em' }}
    >
      <div className="text-lg md:text-2xl font-bold">
        {t('dashboard_dashboard_activities_aktivitäten', 'Aktivitäten')}
      </div>
      <br />
      <div
        className="DashboardActivitiesList grid gap-2"
        style={{ border: '1px solid #CCC' }}
      >
        {aggregatedActivities.map((activity) => {
          return (
            <ActivityView
              key={'' + activity.type + '' + activity.date}
              type={activity.type}
              date={new Date(activity.date)}
              count={activity.count}
            />
          );
        })}
      </div>
      <br />
      <Link
        className="float-right as-link flex flex-row gap-2"
        to="/activities"
      >
        {t(
          'dashboard_dashboard_activities_zu_den_aktivitäten',
          'Zu den Aktivitäten',
        )}{' '}
        <FiArrowRight className="mt-1 text-sm" />
      </Link>
      <br />
    </div>
  );
}

export type AggregatedActivities = {
  [key: string]: {
    [key: string]: Activity[];
  };
};

export const aggregateActivities = (
  activities: Activity[],
): AggregatedActivities => {
  return activities.reduce<AggregatedActivities>((acu, cur) => {
    if (
      cur.type === 'property_expose_download' &&
      cur.source === 'AgentSelly'
    ) {
      return acu;
    }
    const formattedDate = formatDate(cur.createdAt);
    if (acu[formattedDate]) {
      acu[formattedDate][cur.type] = (
        acu[formattedDate][cur.type] || []
      ).concat(cur);
    } else {
      acu[formattedDate] = { [cur.type]: [cur] };
    }
    return acu;
  }, {});
};

export function unreachable(
  value: never,
  message = `No such case in exhaustive switch: ${value}`,
) {
  throw new Error(message);
}
