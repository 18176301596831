import type { Property } from 'api/apiTypes';
import { Clippy } from 'components/Dashboard/Clippy';
import { Notes } from 'components/Dashboard/Notes';
import { DetailPageLayout } from 'components/DetailPageLayout';
import { Person } from 'components/Person';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { assetUrl } from '../../utils/assetUrl';

type ContractProps = {
  selectedPropertyId: number;
  setSelectedPropertyId: Function;
  properties: Array<Property>;
  selectedProperty: Property;
};

export function Contract({
  selectedPropertyId,
  setSelectedPropertyId,
  properties,
  selectedProperty,
}: ContractProps): JSX.Element {
  const { t } = useTranslation();
  const {
    seller,
    internalAgent,
    externalAgent,
    contractDownloadUrl,
    sellContractEndDate,
    sellContractStartDate,
  } = selectedProperty;

  const rightContent = (
    <div>
      <Notes property={selectedProperty}></Notes>
      <Clippy></Clippy>
    </div>
  );

  return (
    <DetailPageLayout
      {...{ selectedPropertyId, setSelectedPropertyId, properties }}
      headerContent={<h4>{t('contract_title')}</h4>}
      rightContent={rightContent}
    >
      <div>
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="border border-gray-border py-5 px-6 md:mr-4 mb-4">
            <h5 className="leading-none text-lg font-bold">
              {t('contract_contract_vertrag', 'Vertrag')}
            </h5>
            <h6 className="leading-none text-base font-bold pt-6 pb-1">
              {t('contract_contract_adresse', 'Adresse')}
            </h6>
            <div>
              {seller.street || ''} {seller.no || ''}
            </div>
            <div>
              {seller.zip || ''} {seller.city || ''}
            </div>
            <h6 className="leading-none text-base font-bold pt-6 pb-1">
              {t('contract_contract_dauer', 'Dauer')}
            </h6>
            <div>
              {formatDate(sellContractStartDate)} -{' '}
              {formatDate(sellContractEndDate)}
            </div>
            <br />
          </div>
          <div className="border border-gray-border py-5 px-6 md:mr-4 mb-4">
            <h5 className="leading-normal text-lg font-bold pb-12">
              {t('contract_contract_downloads', 'Downloads')}
            </h5>
            <a
              href={assetUrl(contractDownloadUrl, {
                secure: true,
              })}
              download={`Vertrag_AgentSelly_${seller.firstname || ''}_${
                seller.lastname || ''
              }`}
            >
              <div className="hover:text-blue-700 hover:border-blue-700 rounded-md flex justify-center items-center border border-blue text-blue w-60 h-12">
                {t('contract_contract_vertrag', 'Vertrag')}
              </div>
            </a>
          </div>
        </div>
        <br />
        <h5>{t('contract_extra_subtitle')}</h5>
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="border-2 border-gray-50 p-4 md:mr-4 mb-4 md:mb-0">
            <Person
              name={`${internalAgent.firstname} ${internalAgent.lastname}`}
              title={
                internalAgent.jobTitle ||
                t('contract_contract_immobilienberater', 'Immobilienberater')
              }
              email={internalAgent.email}
              phone={internalAgent.phone}
              photoUrl={internalAgent.photoUrl}
            />
          </div>
          <div className="border-2 border-gray-50 p-4 mb-4 md:mb-0">
            <Person
              name={`${externalAgent.firstname} ${externalAgent.lastname}`}
              title={
                externalAgent.jobTitle ||
                t('contract_contract_immobilienberater', 'Immobilienberater')
              }
              email={externalAgent.email}
              phone={externalAgent.phone}
              photoUrl={externalAgent.photoUrl}
            />
          </div>
        </div>
      </div>
    </DetailPageLayout>
  );
}
